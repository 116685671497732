import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isBlank} from "../../../../shared/utils/utils";

export const matchPasswordValidator: ValidatorFn = (formGroup: AbstractControl): ValidationErrors | null => {
    const password = formGroup.get('password');
    const cpassword = formGroup.get('cpassword');
    if (isBlank(password) || isBlank(cpassword)) {
        return null;
    }
    const matchPasswordError = {matchPassword: true};
    let errors: ValidationErrors | null = cpassword.errors;
    if (password.value !== cpassword.value) {
        errors = Object.assign(errors || {}, matchPasswordError);
        cpassword.setErrors(errors);
        return matchPasswordError;
    } else {
        cpassword.setErrors(errors);
        return null;
    }
}
