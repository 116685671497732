import {Column} from "../../../shared/decorators/api.decorator";

export class AuthModel {

    @Column()
    token: string = ''
    @Column()
    refresh_token: string = '';

    setAuth(auth: AuthModel) {
        this.token = auth.token;
        this.refresh_token = auth.refresh_token;
    }
}
