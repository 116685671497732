import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, filter, Observable, of, switchMap, take, tap, throwError} from 'rxjs';
import {catchError, finalize, mergeMap} from 'rxjs/operators';
import {AuthService} from "../modules/auth";
import {ErrorService} from "../services/error.service";
import {LoadingService} from "../services/loading.service";
import {CacheService} from "../services/cache.service";
import {Md5} from "ts-md5";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    private isRefreshing: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private readonly authorizationService: AuthService,
        private readonly errorService: ErrorService,
        private readonly loadingService: LoadingService,
        private readonly cacheService: CacheService
    ) {
    }

    /**
     * @inheritDoc
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.withCredentials) {
            return next.handle(this.cloneReq(req)).pipe(
                tap(() => this.loadingService.loadingSubject.next(true)),
                catchError((err: HttpErrorResponse) => {
                    const errorResponse = err as HttpErrorResponse;
                    if (errorResponse.status === 401 && errorResponse.error.message === 'Expired JWT Token') {
                        if (!this.isRefreshing) {
                            this.isRefreshing = true;
                            this.refreshTokenSubject.next(null);
                            return this.authorizationService.refresh().pipe(mergeMap(() => {
                                this.isRefreshing = false;
                                this.refreshTokenSubject.next(this.authorizationService.getAuthFromLocalStorage()?.refresh_token)
                                return next.handle(this.cloneReq(req)).pipe(
                                    finalize(() => {
                                        this.loadingService.loadingSubject.next(false);
                                    })
                                );
                            }));
                        }
                        return this.refreshTokenSubject.pipe(
                            filter(token => token !== null),
                            take(1),
                            switchMap((token) => next.handle(this.cloneReq(req))),
                            finalize(() => {
                                this.loadingService.loadingSubject.next(false);
                            })
                        );
                    }
                    this.errorService.throwError(err).then();
                    this.loadingService.loadingSubject.next(false);
                    return throwError(() => err);
                }),
                tap(() => {
                    this.loadingService.loadingSubject.next(false);
                }));
        } else {
            const url = new URL(req.urlWithParams);
            const key = Md5.hashStr(url.pathname + url.searchParams.toString());
            if (this.cacheService.hasStateKey(key)) {
                const data = this.cacheService.getStateKey(key, undefined);
                return of(new HttpResponse(data));
            }
            return next.handle(req).pipe(
                finalize(() => {
                    this.loadingService.loadingSubject.next(false);
                }),
                catchError((err: HttpErrorResponse) => {
                    this.errorService.throwError(err);
                    this.loadingService.loadingSubject.next(false);
                    return throwError(() => err);
                })
            );
        }
    }

    private cloneReq(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone(
            {
                setHeaders: {
                    Authorization: 'Bearer ' + this.authorizationService.getAuthFromLocalStorage()?.token
                }
            }
        );
    }
}
